import React from "react";
import styles from "./aboutsectionone.module.css";
import vrImage from "../../../assets/about/ABOUT_FIRST.jpg"; // Replace with your actual image import

const AboutSectionOne = () => {
  return (
    <div className={styles.dentistrySection}>
      <div className={styles.leftSide}>
        <img src={vrImage} alt="Digital Dentistry" className={styles.dentistryImage} />
        <div className={styles.overlayText}>
          <h1>Our  team of experts help budding dentists to master digital dentistry skills.</h1>
          <p>The ultimate experiential learning company to step into the world of digital dentistry.</p>
        </div>
      </div>

      <div className={styles.rightSide}>
        <h5>Why Choose Us?</h5>
        <h2>
          Master Digital Dentistry With Our Immersive AR/VR Training. Learn At Your Own Pace, Anytime, Anywhere,
          With Flexible, Personalized Modules Taught By Industry Leaders. Our Scalable Online Platform Ensures
          Top-Quality Education That Fits Your Schedule And Budget.
        </h2>
        <div className={styles.contactInfo}>
          <p>Call Us:</p>
          <button className={styles.contactBtn}>+91-8880003344</button>
        </div>
      </div>
    </div>
  );
};

export default AboutSectionOne;
