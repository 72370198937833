import { useState } from 'react';
import formImg from '../../../assets/formImg.svg'
import Button from '../../Buttons';
import Textfield from '../../Textfield';
import TextInput from '../../TextInput';
import DatePicker from '../../TextInput/DatePicker';
import Select from '../../TextInput/Select';
import styles from './contactFormStyles.module.css'
import useWindowDimensions from '../../../hooks/useWindowsDimention';

const ContactForm = () => {

    const [date, setDate] = useState("");
    const [selectedOptionCourse, setSelectedOptionCourse] = useState("BLANK");
    const [selectedOptionPackage, setSelectedOptionPackage] = useState("BLANK");

    const { width } = useWindowDimensions();

    const options = [
        { value: "BLANK", label: "Select a Course" },
        { value: "DENTAL_COURSE", label: "Dental Clinic Course" },
        { value: "LAB", label: "Dental Lab Course" },
      ];
    
      const optionsPackage = [
        { value: "BLANK", label: "Select a Package" },
        { value: "DENTAL_COURSE", label: "Base Package" },
        { value: "DENTAL_COURSE", label: "“Try Me” VR Package," },
        { value: "DENTAL_COURSE", label: "“Test Me” VR Package " },

      ];
    return (
        <div className={styles.formContainer}>
            <img src={formImg} alt='formImg' />
            <div className={styles.form}>
                <Textfield colorType="blue" type="h1" style={{ color: 'white', ...(width < 600 && { fontSize: '24px' }) }}>
                    Take a chance for your future <br /> Book an online slot now.
                </Textfield>
                <Textfield
                    colorType="blue"
                    type="h5"
                    style={{ marginTop: 10, fontWeight: 400, color: 'white' }}
                >
                  Fill in the details below and start your course. 
                </Textfield>
                <div className={styles.formMain}>
                    <div className={styles.flexform}>
                        <TextInput
                            style={{ width: "100%" }}
                            label="Name*"
                            placeholder="Name*"
                            inputStyle={{ backgroundColor: '#FFFFFF33', border: '1.06px solid #FFFFFF33' }}
                        ></TextInput>
                        <TextInput
                            style={{ width: "100%" }}
                            label="Email*"
                            placeholder="Email*"
                            inputStyle={{ backgroundColor: '#FFFFFF33', border: '1.06px solid #FFFFFF33' }}
                        ></TextInput>
                    </div>
                    <div className={styles.flexform}>
                    <TextInput
                            style={{ width: "100%" }}
                            label="Phone*"
                            placeholder="Phone*"
                            inputStyle={{ backgroundColor: '#FFFFFF33', border: '1.06px solid #FFFFFF33' }}
                        ></TextInput>
                        <Select
                            value={selectedOptionCourse}
                            onChange={(e) => setSelectedOptionCourse(e.target.value)}
                            options={options}
                            style={{ width: "100%" }}
              
                            selectStyle={{ backgroundColor: '#FFFFFF33', border: '1.06px solid #FFFFFF33', color: 'white' }}
                        />
                        
                    </div>
                    <div className={styles.flexform}>
                    <Select
                            value={selectedOptionPackage}
                            onChange={(e) => setSelectedOptionPackage(e.target.value)}
                            options={optionsPackage}
                            style={{ width: "100%" }}
                            selectStyle={{ backgroundColor: '#FFFFFF33', border: '1.06px solid #FFFFFF33', color: 'white' }}
                        />
                    </div>
                    <Button style={{ backgroundColor: 'white', color: 'black', border: 'none' }}>Book your free demo class now</Button>
                </div>
            </div>
        </div>
    )
}

export default ContactForm;