import { useState } from 'react';
import minus from '../../../assets/minus.svg'
import plus from '../../../assets/plus.svg'
import Textfield from '../../Textfield'
import styles from './faqStyles.module.css';

const FaqAccordian = ({ question, answer }) => {

    const [isOpen, setIsOpen] = useState(false)

    const toggleHandler = () => {
        setIsOpen((prevState) => !prevState);
    }

    return (
        <div className={styles.faqContainer}>
            <div className={styles.questionDiv}>
                <Textfield type="h4" >
                    {question}
                </Textfield>
                {isOpen ? <img src={minus} alt='minus' onClick={toggleHandler} /> : <img src={plus} alt='plus' onClick={toggleHandler} />}
            </div>
            {isOpen && <div>
                <Textfield type="h6" style={{ 'marginBlock': '10px', 'margin-right': '30px', 'lineHeight': '1.5', color: '#00000080' }}>
                    {answer}
                </Textfield>
            </div>}
        </div>
    )

}

export default FaqAccordian;