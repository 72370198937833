import React from "react";
import styles from "./Header.module.css";
import Textfield from "../../components/Textfield";
import Button from "../../components/Buttons";
import { IoPlay } from "react-icons/io5";
import { WiDirectionLeft, WiDirectionRight } from "react-icons/wi";
import SliderImage from "../../assets/home/hero section small image.png" 
import { Link } from "react-router-dom";
const Header = () => {
  return (
    <div className={styles.hero}>
      <div className={styles.heroText}>
        <Textfield colorType="white" type="h1" style={{ fontSize: 42 }}>
        Gain Real-world Experience <br />
        with DGA’s VR Simulation<br />
          {/* with DGA */}
        </Textfield>
        <Textfield
          colorType="grey"
          type="h5"
          style={{ fontWeight: 300, marginTop: 10, marginRight: 120 }}
        >
         With our expert VR classes, be a professional before you become a professional. Digident Global <br/> Academy courses help you set yourself apart from the rest. 
        </Textfield>
        <div className={styles.btnMain}>
          <Link to="/contact">
          <Button type="allBlue">Contact Us</Button>
          </Link>
          {/* <div className={styles.playBtn}>
            <Textfield colorType="white" style={{ fontWeight: 400 }}>
              Watch the Video
            </Textfield>
            <div className={styles.circle}>
              <IoPlay color="#023a65" size={18} />
            </div>
          </div> */}
        </div>
      </div>

      {/* Side Banner*/}
      {/* <div className={styles.heroImage}>
        <div className={styles.banner}>
          <img
            src={SliderImage}
            alt="banner"
          />
          <div className={styles.bannerText}>
            <div style={{ display: "flex", gap: 10, alignItems: "center" }}>
              <Textfield colorType="blue" type="h5">
                Tooth Daycare.
              </Textfield>
              <Textfield colorType="blue" type="h6" style={{ fontWeight: 400 }}>
                Beautiful Smile
              </Textfield>
            </div>
            <div style={{ display: "flex", gap: 10, alignItems: "center" }}>
              <WiDirectionLeft color="#023a65" size={24} />
              <WiDirectionRight color="#023a65" size={36} />
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default Header;
