import React from "react";
import styles from "./works.module.css";
import Textfield from "../../components/Textfield";
import Button from "../../components/Buttons";
import work1 from "../../assets/course/CG_1.png";
import work2 from "../../assets/course/CG_2.png";
import work3 from "../../assets/course/CG_3.png";


import { HashLink as HLink } from "react-router-hash-link";
const CourseHowItWorks = () => {
  return (
    <div className={styles.work}>
      <Textfield colorType="blue" type="h1">
        The Package Advantage
      </Textfield>
      <Textfield
        colorType="blue"
        type="h5"
        style={{ marginTop: 10, fontWeight: 400 }}
      >
        Our Clinical Suites Modules are designed as per different learning .
        <br />
        preferences & objectives within the dental sector
      </Textfield>
      <div className={styles.steps}>
        <div className={styles.step}>
          <img src={work1} alt="" />
          <Textfield colorType="blue" type="h1">
            Base Package
          </Textfield>
          <Textfield
            colorType="blue"
            type="h5"
            style={{ marginTop: 10, fontWeight: 400 }}
          >
The Base Module offers a comprehensive online learning experience with high-quality video content and engaging gamification, making it perfect for those looking to strengthen their clinical knowledge. 
          </Textfield>
        </div>
        <div className={styles.step}>
          <img src={work2} alt="" />
          <Textfield colorType="blue" type="h1">
          “Try Me” VR Package
          </Textfield>
          <Textfield
            colorType="blue"
            type="h5"
            style={{ marginTop: 10, fontWeight: 400 }}
          >
            The "TRY ME" VR Experience is an add-on to the Base Module that offers trainees unlimited access to immersive VR training scenarios. This allows them to practice as much as they need in a safe virtual environment before applying their skills to real patients. 
          </Textfield>
        </div>
        <div className={styles.step}>
          <img src={work3} alt="" />
          <Textfield colorType="blue" type="h1">
          “Test Me” VR Package  
          </Textfield>
          <Textfield
            colorType="blue"
            type="h5"
            style={{ marginTop: 10, fontWeight: 400 }}
          >
            The VR Module is the most advanced part of our Clinical Suite, allowing users to practice dentistry in a virtual world. They can wear a VR headset and get hands-on experience without any risk. This includes a "TEST ME" feature to objectively measure skills before working on real patients. 
          </Textfield>
        </div>
      </div>
      <HLink smooth to="#contactus">  <Button>book a free demo class</Button></HLink>
    </div>
  );
};

export default CourseHowItWorks;
