import React, { useEffect, useState } from "react";
import Header from "../Header";
import Topnav from "../../components/TopNav";
import Navbar from "../../components/Navbar";
import styles from "./Layout.module.css";
import Footer from "../Footer";
import BookAppointmentBanner from "../BookAppointmentBanner";
import ContactBanner from "../ContactBanner";
import useWindowDimensions from "../../hooks/useWindowsDimention";

const Layout = ({ children, isHome = false, isContactBanner = true }) => {
  const [scrolled, setScrolled] = useState(false);
  const { width } = useWindowDimensions();

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  console.log("Scrolled", scrolled);
  return (
    <div className={styles.layout}>
      <div className={styles.nav}>
        {width > 900 ? (
          <Topnav type={isHome ? (scrolled ? "light" : "dark") : "light"} />
        ) : null}
        {
          width > 950 ?  (  <Navbar theme={isHome ? (scrolled ? "light" : "dark") : "light"} />):
          scrolled ?   <Navbar theme={isHome ? (scrolled ? "light" : "dark") : "light"} /> : null
        }
      
      </div>

      {isHome ? (
        <div style={{ backgroundColor: "#cdebff" }}>
          <div className={styles.header}>
            <Header />
          </div>
        </div>
      ) : null}
      <div className={styles.main}>{children}</div>
      {isContactBanner && <ContactBanner />}
      {/* <BookAppointmentBanner /> */}
      <Footer />
    </div>
  );
};

export default Layout;
