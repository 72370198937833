import React from "react";
import styles from "./smile.module.css";
import course1 from "../../assets/course.png";
import Textfield from "../../components/Textfield";

const data = [
  {
    id: 1,
    title: "Perfect Virtual Experience  ",
    desc: "The learning professional will have previous experience via VR, so they feel confident when operating on real patients",
  },
  {
    id: 2,
    title: "Shift from Boring and Dull Training ",
    desc: "VR training will help in making the experience more interactive, engaging, and interactive.  ",
  },
  {
    id: 3,
    title: "Shift Towards Online & Remote Learning ",
    desc: "The last few years have accelerated the shift to online training, with learners valuing the importance of digital education.",
  },
  {
    id: 4,
    title: "Better Knowledge Retention ",
    desc: "Professionals can have better knowledge retention of how to operate on patients by voluntarily participating in the training sessions. ",
  },
];

const CourseSmileSteps = () => {
  return (
    <div className={styles.smileContainer}>
      <div className={styles.whiteContainer}>
        <div className={styles.infoContainer}>
          <Textfield
            type="h1"
            style={{
              marginBlock: "20px",
              fontWeight: "bolder",
              textAlign: "left",
            }}
          >
        How will VR help me in the dental sector?  
          
          </Textfield>
          <div className={styles.itemContainer}>
            {data.map((item) => (
              <ListContainer id={item.id} title={item.title} desc={item.desc} />
            ))}
          </div>
        </div>
        <div className={styles.imageContainer}>
          <img src={course1} alt="" />
        </div>
      </div>
    </div>
  );
};

export default CourseSmileSteps;

const ListContainer = ({ id, title, desc }) => {
  return (
    <div className={styles.item}>
      <div className={styles.numbercirlce}>
        <div className={styles.number}>{id}</div>
      </div>
      <div className={styles.info}>
        <Textfield
          type="h4"
          style={{
            textAlign: "left",
          }}
        >
          {title}
        </Textfield>
        <Textfield
          type="span"
          style={{
            textAlign: "left",
            fontWeight: 300,
            fontSize: 13,
          }}
        >
          {desc}
        </Textfield>
      </div>
    </div>
  );
};
