import React from "react";
import { IoPlayCircleOutline } from "react-icons/io5";
import Button from "../../components/Buttons";
import Textfield from "../../components/Textfield";
import ContactInfo from "../../molecules/Home/ContactInfo";
import Layout from "../../molecules/Layout";
import styles from "./Home.module.css";
import spec1 from "../../assets/spec1.svg";
import spec2 from "../../assets/spec2.svg";
import spec3 from "../../assets/spec3.png";
import spec4 from "../../assets/spec4.svg";
import smallImage from "../../assets/smallImage.png";
import course1 from "../../assets/home/ct_1.png";
import course2 from "../../assets/home/ct_2.png";
import benefitsTeeth from '../../assets/benifits-teeth.svg'
import Slider from "../../molecules/Slider";
import useWindowDimensions from "../../hooks/useWindowsDimention";
import { CardSlider } from "../../molecules/CardSlider";
import { HashLink as HLink } from 'react-router-hash-link';
import Carousel3D from "../../molecules/Carousel3D";
import C3D from "../../molecules/C3D/inex";
import { Link } from "react-router-dom";
import videoFile from '../../assets/Video/v1.mp4'; 
import videoFile2 from '../../assets/Video/v2.mp4'; 
import CarouselMob from "../../molecules/CarouselMob";


const Home = () => {
  const { width } = useWindowDimensions();

  const handleClick = (type) => {
    if(type === "lab"){
      window.location.href = "courses?course=lab"
    }
    if(type === "dental"){
      window.location.href = "courses?course=dental"
    }
  }
  return (
    <Layout isHome>
      <div className={styles.profesion}>
        <Textfield colorType="blue" type="h1">
          Our History
        </Textfield>
        <Textfield
          colorType="blue"
          type="h5"
          style={{ marginTop: 10, fontWeight: 500 }}
        >
         DGA was founded by Dr. Manish Chhabra, a digital dentistry pioneer with 17+ years of experience. He identified a gap in accessible, immersive training for <br/> dentists and dental assistants to master new technologies. DGA's VR-powered platform empowers dentists with convenient, cost-effective training,<br/> reducing their reliance on assistants and freeing their time for patient care. DGA aims to revolutionize   dental training through immersive,<br/> engaging experiences that fit users' schedules. 
        </Textfield>
        <HLink smooth to="#contactus">  <Button>Enroll Now </Button> </HLink>
      </div>
      <Slider />
      <div className={styles.courses}>
        <ColorButton label="Our Courses" />
        <Textfield colorType="blue" type="h1" style={{ lineHeight: 1.1 }}>
          Master Digital Dentistry
          <br /> with Our Skills
        </Textfield>
        <div className={styles.courseContainer}>
          <div className={styles.blueCard} onClick={()=>{handleClick("dental")}}>
            {/* <ColorButton label="Dental" type="blue" /> */}
            <Textfield colorType="white" type="h2"
            style={{ marginTop: 0,marginBottom: 0}}>
             Dental Clinic Courses
            </Textfield>
            <Textfield
              colorType="grey"
              type="h5"
              style={{ marginTop: 0, fontWeight: 500, marginBottom: 0 }}
            >
               Learn all courses of digital dentistry regarding dental clinics
            </Textfield>
            <img src={course1} alt="" />
          </div>
          <div className={styles.blueCard} onClick={()=>{handleClick("lab")}}>
     
            {/* <ColorButton label="Dental" type="blue" /> */}
            <Textfield colorType="white" type="h2">
            Dental Lab Courses
            </Textfield>
            <Textfield
              colorType="grey"
              type="h5"
              style={{ marginTop: 0, fontWeight: 400, marginRight: 150 }}
            >
              Learn all courses of digital dentistry regarding dental labs
            </Textfield>
            <img src={course2} alt="" />

          </div>
        </div>
      </div>

      <div className={styles.bluesmallBanner}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div className={styles.overlayStyle}>
            <img src={smallImage} alt="" />
            <div className={styles.blueOverlayStyle}></div>
          </div>
          <div className={styles.flexCol}>
            <Textfield colorType="white" type="h1">
              Get An Expert Professor Opinion
            </Textfield>
            {width > 900 ? (
              <Textfield colorType="grey" type="h5">
                Our courses are designed for levelling up your skills and making you an expert
              </Textfield>
            ) : null}
          </div>
        </div>

        <HLink smooth to="#contactus">  <Button type="white">Book A Demo</Button> </HLink>
      </div>

      <div className={styles.booknow}>
        <div className={styles.benefitsBox}>
          <Textfield colorType="white" type="h2">
            Benefits You Will Get In Our Courses
          </Textfield>
          <Textfield colorType="white" type="h5">
          Our courses will help you get set to make a mark in the dental sector. 
          </Textfield>
          <div className={styles.benefits}>
            <div>
              <img src={benefitsTeeth} alt="benefits-tooth" />
              <Textfield colorType="white" type="h5">
           
              Variable <br /> Learning Module
              </Textfield>
            </div>
            <div>
              <img src={benefitsTeeth} alt="benefits-tooth" />
              <Textfield colorType="white" type="h5">
              Top Industry<br /> Educators
              </Textfield>
            </div>
            <div>
              <img src={benefitsTeeth} alt="benefits-tooth" />
              <Textfield colorType="white" type="h5">
              Self-Paced <br /> Learning
              </Textfield>
            </div>
            <div>
              <img src={benefitsTeeth} alt="benefits-tooth" />
              <Textfield colorType="white" type="h5">
              Experiential <br /> Learning
              </Textfield>
            </div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <HLink smooth to="#contactus">  
            <Button style={{ border: '20px', padding: '10px', marginInline: 'auto !important', fontSize: '10px !important' }}>Book A Demo {'->'}</Button>
            </HLink>
          </div>
        </div>
        <div className={styles.booknowItem} >
      <Carousel3D /> 
           

        </div>

    <div className={styles.booknowItemMob} >
      <CarouselMob/> 

        </div>

      </div>

      <div className={styles.spec}>
        <ColorButton label="Our Course" />
        <Textfield colorType="blue" type="h1">
          Our Speciality
        </Textfield>
        <div className={styles.specList}>
          <div className={styles.specItem}>
            <img src={spec1} alt="" />
            <Textfield colorType="blue" type="h2">
              8+
            </Textfield>
            <Textfield colorType="grey">
              Courses{" "}
            </Textfield>
          </div>
          <div className={styles.specItem}>
            <img src={spec2} alt="" />
            <Textfield colorType="blue" type="h2">
              5+
            </Textfield>
            <Textfield colorType="grey">
              Educators on our panel {" "}
            </Textfield>
          </div>
          <div className={styles.specItem}>
            <img src={spec3} alt="" />
            <Textfield colorType="blue" type="h2">
              500+
            </Textfield>
            <Textfield colorType="grey">
              Learners{" "}
            </Textfield>
          </div>
          <div className={styles.specItem}>
            <img src={spec4} alt="" />
            <Textfield colorType="blue" type="h2">
              1000+
            </Textfield>
            <Textfield colorType="grey">
              Mins of VR learning{" "}
            </Textfield>
          </div>
        </div>
      </div>

      {/* <div className={styles.videoSection}>
        <div className={styles.alwaysHere}>
          <IoPlayCircleOutline
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%,-50%)",
            }}
            color="#fff"
            size={70}
          />
          <Textfield
            colorType="white"
            type="h1"
            style={{ position: "absolute", bottom: "70px", left: "40px" }}
          >
            Always Here for Your
            <br />
            Dental Education
          </Textfield>
        </div>
      </div> */}

<div className={styles.videoSection}>
  {/* Left Side Video */}
  <div className={styles.videoWrapper}>
    <video 
      autoPlay 
      loop 
      muted 
      playsInline 
      className={styles.videoBackground}
    >
      <source src={videoFile} type="video/mp4" />
      Your browser does not support the video tag.
    </video>
  </div>

  {/* Right Side Text */}
  <div className={styles.textWrapper}>
    <h1 className={styles.title}>Hands-on Practicality with VR Training</h1>
    <p className={styles.description}>
    Discover the power of immersive learning with DGA's exceptional VR experiences, designed to equip budding professionals with hands-on expertise in a safe, simulated environment. Our state-of-the-art technology ensures they gain confidence and proficiency, eliminating fears before transitioning to real-world practice.
    </p>
  </div>
</div>



      <ContactInfo />
    </Layout>
  );
};

export default Home;

const ColorButton = ({ type = "white", label }) => {
  return (
    <div className={type === "white" ? styles.whiteButton : styles.blueButton}>
      {label}
    </div>
  );
};
