import React, { useState } from 'react';
import styles from './carouselMob.module.css'; // Updated CSS file name
import img1 from "../../assets/home/ThreeD/Img1.png"
import img2 from "../../assets/home/ThreeD/Img2.png"
import img3 from "../../assets/home/ThreeD/Img3.png"
import img4 from "../../assets/home/ThreeD/Img4.png"

const CarouselMob = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
 
  const carouselItems = [
    {
      id: 1,
      imageUrl: img1,
      title: 'AR/VR Powered Experiential Learning',
      description: 'Learn by doing, not just seeing, with AR/VR powered experiential learning.',
      badge: 'Popular',
    },
    {
      id: 2,
      imageUrl: img2,
      title: "Top Industry Educators on the Panel",
      description: 'Gain expertise from reputed industry experts on our panel.',
      badge: 'Popular',
    },
    {
      id: 3,
      imageUrl: img3,
      title: 'Self-Paced Learning',
      description: 'Learn at your own pace, anytime, anywhere.',
      badge: 'Popular',
    },
  ];


  
  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % carouselItems.length);
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + carouselItems.length) % carouselItems.length);
  };

  return (
    <div className={styles.carouselContainer}>
      <div className={styles.carousel}>
        {carouselItems.map((item, index) => (
          <div
            key={item.id}
            className={`${styles.carouselItem} ${index === currentIndex ? styles.active : ''}`}
          >
            <div className={styles.card}>
              <img src={item.imageUrl} alt={item.title} className={styles.cardImage} />
              <div className={styles.cardContent}>
                <span className={styles.badge}>{item.badge}</span>
                <h3>{item.title}</h3>
                <p>{item.description}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className={styles.navigation}>
        <button onClick={prevSlide}>&larr;</button>
        <button onClick={nextSlide}>&rarr;</button>
      </div>
    </div>
  );
};

export default CarouselMob;
