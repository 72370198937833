import React from "react";
import overview1 from "../../assets/course/overview1.svg";
import overview2 from "../../assets/course/overview2.svg";
import overview3 from "../../assets/course/overview3.svg";
import overview4 from "../../assets/course/overview4.svg";
import overview5 from "../../assets/course/overview5.svg";
import overview6 from "../../assets/course/overview6.svg";
import check from "../../assets/course/check-mark 3.png";
import styles from "./coursetabview.module.css";
import Textfield from "../../components/Textfield";

const CourseOverview = () => {
  const cards = [
    {
      icon: overview1,
      text: "VR Device Enabled  ",
    },
    {
      icon: overview2,
      text: "Hands-on Approach  ",
    },

    {
      icon: overview3,
      text: "CE Credits",
    },
    {
      icon: overview4,
      text: "Video Assessment ",
    },
    {
      icon: overview4,
      text: "LMS Integration  ",
    },
    {
      icon: overview6,
      text: "Certificate on Completion",
    },
  ];
  return (
    <>
    <h1 className={styles.overviewHeader}>What Do We Offer in the Course?</h1>
    <div className={styles.overview}> 
      
      {cards.map((card, id) => (
        <div key={id} className={styles.overviewCard}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: 15,
              textTransform: "capitalize",
            }}
          >
            <img src={card.icon} alt="" />
            <Textfield type="span" style={{ fontSize: 14 }}>
              {card.text}
            </Textfield>
          </div>
          <img src={check} alt="" />
        </div>
      ))}
    </div>
    </>
  );
};

export default CourseOverview;
