import styles from './courseCardStyles.module.css'
import lightLogo from '../../../assets/logolight.png'
import Textfield from '../../Textfield'
import Button from '../../Buttons'
import { Link } from "react-router-dom";
const CoursesCard = ({ imgSrc, courseName, description, keyPoints, showCommingSoon = false,link="courses-detail" }) => {


    return (<div className={styles.card}>
        <div className={styles.imgDiv} style={{ backgroundImage: `url(${imgSrc})` }}>
            <img src={lightLogo} alt='logo' />
            {/* {showCommingSoon && <div className={styles.commingSoonDiv}>
                <span className={styles.comingSoonSpan}>
                    Comming Soon
                </span>
            </div>} */}
        </div>
        <Textfield type="h3" colorType='blue' style={{ marginTop: '20px', marginBottom: '10px' }} >
            {courseName}
        </Textfield>
        <Textfield type="h6" colorType='black' style={{ marginBlock: '10px' }} >
            {description}
        </Textfield>
        <hr />
        <Textfield type="h3" style={{ 'margin': '10px' }}>
            KEY POINTS
        </Textfield>

        <ul style={{ 'margin': '10px' }}>
            {keyPoints.map((el) => {
                return <li style={{ marginLeft: '15px' }}>{
                    <Textfield type="h5" style={{ 'marginBlock': '10px', color: '#171717AB', fontWeight: 'bold' }}>
                        {el}
                    </Textfield>}</li>
            })}
        </ul>
        <div className={styles.btnDiv}>
            {!showCommingSoon?
              <Link to="/courses-detail"> <Button type="filled" style={{ borderRadius: '24px' }} >
                View Details
            </Button>
            </Link>:<Button type="filled" style={{ borderRadius: '24px' }} >
                Coming Soon
            </Button>
            }
        </div>
    </div>
    )
}

export default CoursesCard;