import React from "react";
import styles from "./Topnav.module.css";
import Textfield from "../Textfield";
import {
  FaFacebook,
  FaInstagram,
  FaLinkedin,
  FaRegClock,
} from "react-icons/fa";
import { BsTwitterX } from "react-icons/bs";
import { IoMdCall } from "react-icons/io";
const Topnav = ({ type = "dark" }) => {
  let color = type === "light" ? "#000" : "#9f9f9f";
  let Iconcolor = type === "light" ? "#006DBD" : "#9f9f9f";
  let SIconcolor = type === "light" ? "#9f9f9f" : "#fff";


  return (
    <div
      className={styles.topnav}
      style={{
        borderBottom: type==="light"? `1px solid rgba(0,0,0, 0.2)` : `1px solid rgba(255, 255, 255, 0.2)`,
        backgroundColor: type === "light" ? "#fff" : "transparent",
       
      }}
    >
      <div className={styles.left}>
        <div className={styles.social}>
          <FaFacebook fontSize={14} color={SIconcolor} />
          <Textfield type="h5" style={{ color: color }}>
            Facebook
          </Textfield>
        </div>
        <div className={styles.social}>
          <FaInstagram fontSize={14} color={SIconcolor} />
          <Textfield type="h5" style={{ color: color }}>
            Instagram
          </Textfield>
        </div>
        <div className={styles.social}>
          <BsTwitterX fontSize={14} color={SIconcolor} />
          <Textfield type="h5" style={{ color: color }}>
            Twitter
          </Textfield>
        </div>
        <div className={styles.social} >
          <FaLinkedin fontSize={14} color={SIconcolor} />
          <Textfield type="h5" style={{ color: color }}>
            Linkedin
          </Textfield>
        </div>
      </div>
      <div className={styles.right}>
        <div className={styles.social}>
          <div className={styles.iconButton} style={{ background: Iconcolor }}>
            <FaRegClock fontSize={15} color={"#fff"} />
          </div>
          <Textfield type="h5" style={{ color: color }}>
            Monday to Friday 9AM-10PM
          </Textfield>
        </div>
        <div className={styles.social}>
          <div className={styles.iconButton} style={{ background: Iconcolor }}>
            <IoMdCall fontSize={14} color={"#fff"} />
          </div>
          <Textfield type="h5" style={{ color: color }}>
            Call us +919919191991
          </Textfield>
        </div>
      </div>
    </div>
  );
};

export default Topnav;
