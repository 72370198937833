import React from "react";
import styles from "./pricing.module.css";
import Textfield from "../../components/Textfield";
import { IoIosArrowDown } from "react-icons/io";
import logodark from "../../assets/logodark.png";
import check2 from "../../assets/course/check2.svg";
import cancel from "../../assets/course/cancel.svg";

const CoursePricing = () => {
  return (
    <>
      <div className={styles.pricing}>
        <div className={styles.pricingContainer}>
          <div className={styles.accordionContainer}>
            <Textfield type="h2" style={{ marginBottom: 20 }}>
              Features
            </Textfield>
            <Accordian title={"Pricing "} decs="desc" />
            <Accordian title={"Approach "} decs="desc" />
            <Accordian title={"Scope of Learning "} decs="desc" />
            <Accordian title={"User Journey "} decs="desc" />
            <Accordian title={"Tracking Parameters "} decs="desc" />
            <Accordian title={"Learning Pattern "} decs="desc" />
          </div>
          <div className={styles.bluePricing}>
            <img src={logodark} alt="" />
            <Textfield
              type="span"
              style={{ fontSize: 14, padding: "25px 40px" }}
            >
              Affordable
            </Textfield>

            <Textfield
              type="span"
              style={{ fontSize: 14, padding: "25px 40px" }}
            >
              Hands-On & Practical
            </Textfield>

            <Textfield
              type="span"
              style={{ fontSize: 14, padding: "25px 40px" }}
            >
              Flexible & Self-paced
            </Textfield>
            <Textfield
              type="span"
              style={{ fontSize: 14, padding: "25px 40px" }}
            >
              Interactive
            </Textfield>
            <Textfield
              type="span"
              style={{ fontSize: 14, padding: "25px 40px" }}
            >
              LMS
            </Textfield>
            <Textfield
              type="span"
              style={{ fontSize: 14, padding: "25px 40px" }}
            >
              VR & Simulation
            </Textfield>
          </div>
          <div className={styles.whitePricing}>
            <h2>Other Academy</h2>
            <Textfield
              type="span"
              style={{ fontSize: 14, padding: "25px 40px" }}
            >
              High-End
            </Textfield>

            <Textfield
              type="span"
              style={{ fontSize: 14, padding: "25px 40px" }}
            >
           Theoretical 
            </Textfield>

            <Textfield
              type="span"
              style={{ fontSize: 14, padding: "25px 40px" }}
            >
       Fixed & Supervised 
            </Textfield>
            <Textfield
              type="span"
              style={{ fontSize: 14, padding: "25px 40px" }}
            >
              Autonomous 
            </Textfield>
            <Textfield
              type="span"
              style={{ fontSize: 14, padding: "25px 40px" }}
            >
              Manual 
            </Textfield>
            <Textfield
              type="span"
              style={{ fontSize: 14, padding: "25px 40px" }}
            >
             Online & Webinar 
            </Textfield>
          </div>
        </div>
      </div>
    </>
  );
};

export default CoursePricing;

const Accordian = ({ title, desc }) => {
  return (
    <div className={styles.acordion}>
      <div className={styles.accordionTop}>
        <Textfield type="span" style={{ fontSize: 14 }}>
          {title}
        </Textfield>
        {/* <IoIosArrowDown /> */}
      </div>
    </div>
  );
};
